@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
.snippet * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.snippet *:before,
.snippet *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.snippet a {
  background-color: transparent;
}
.snippet a:active,
.snippet a:hover {
  outline: 0;
}
.snippet h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
.snippet h1,
.snippet .h1,
.snippet h2,
.snippet .h2,
.snippet h3,
.snippet .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.snippet h4,
.snippet .h4,
.snippet h5,
.snippet .h5,
.snippet h6,
.snippet .h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.snippet h1,
.snippet .h1 {
  font-size: 36px;
}
.snippet h2,
.snippet .h2 {
  font-size: 30px;
}
.snippet h3,
.snippet .h3 {
  font-size: 24px;
}
.snippet h4,
.snippet .h4 {
  font-size: 18px;
}
.snippet h5,
.snippet .h5 {
  font-size: 14px;
}
.snippet h6,
.snippet .h6 {
  font-size: 12px;
}
.snippet img {
  border: 0;
}
.snippet svg:not(:root) {
  overflow: hidden;
}
.snippet hr {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
.snippet a {
  color: #337ab7;
  text-decoration: none;
}
.snippet a:hover,
.snippet a:focus {
  color: #23527c;
  text-decoration: underline;
}
.snippet a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.snippet img {
  vertical-align: middle;
}
.snippet hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}
.snippet h1,
.snippet h2,
.snippet h3,
.snippet h4,
.snippet h5,
.snippet h6,
.snippet .h1,
.snippet .h2,
.snippet .h3,
.snippet .h4,
.snippet .h5,
.snippet .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.snippet .snippet-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.snippet .btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: none;
}
.snippet .btn:focus,
.snippet .btn:active:focus,
.snippet .btn.active:focus,
.snippet .btn.focus,
.snippet .btn:active.focus,
.snippet .btn.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.snippet .btn:hover,
.snippet .btn:focus,
.snippet .btn.focus {
  color: #333333;
  text-decoration: none;
}
.snippet .btn:active,
.snippet .btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.snippet .btn.disabled,
.snippet .btn[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.snippet .btn-primary {
  color: #ffffff;
  background-color: #0183cc;
  border-color: #0183cc;
}
.snippet .btn-primary:hover,
.snippet .btn-primary:focus,
.snippet .btn-primary.focus,
.snippet .btn-primary:active,
.snippet .btn-primary.active {
  color: #ffffff;
  background-color: #006299;
  border-color: #005c8f;
}
.snippet .btn-primary:active,
.snippet .btn-primary.active {
  background-image: none;
}
.snippet .btn-primary.disabled,
.snippet .btn-primary[disabled],
.snippet .btn-primary.disabled:hover,
.snippet .btn-primary[disabled]:hover,
.snippet .btn-primary.disabled:focus,
.snippet .btn-primary[disabled]:focus,
.snippet .btn-primary.disabled.focus,
.snippet .btn-primary[disabled].focus,
.snippet .btn-primary.disabled:active,
.snippet .btn-primary[disabled]:active,
.snippet .btn-primary.disabled.active,
.snippet .btn-primary[disabled].active {
  background-color: #337ab7;
  border-color: #2e6da4;
}
.snippet .clearfix:before,
.snippet .clearfix:after,
.snippet .snippet-container-fluid:before,
.snippet .snippet-container-fluid:after,
.snippet .snippet-row:before,
.snippet .snippet-row:after {
  content: " ";
  display: table;
}
.snippet .clearfix:after,
.snippet .snippet-container-fluid:after,
.snippet .snippet-row:after {
  clear: both;
}
.snippet .hide {
  display: none !important;
}
.snippet .show {
  display: block !important;
}
.snippet .invisible {
  visibility: hidden;
}
.snippet .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.snippet .hidden {
  display: none !important;
}
@-ms-viewport {
  width: device-width;
}
.snippet .btn-primary {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
}
.snippet .btn-primary:active,
.snippet .btn-primary.active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.snippet .btn:active,
.snippet .btn.active {
  background-image: none;
}
.snippet .btn-primary {
  padding: 10px 10px;
  background: #0183cc;
  border: 2px solid white;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: white;
  text-decoration: none;
  display: inline-block;
}
.snippet .btn-primary:hover,
.snippet .btn-primary:focus {
  background-color: #006299;
  border-color: #005c8f;
}
.snippet .btn-primary:active,
.snippet .btn-primary.active {
  background-color: #265a88;
  border-color: #245580;
}
.snippet .btn-primary.disabled,
.snippet .btn-primary:disabled,
.snippet .btn-primary[disabled] {
  background-color: #265a88;
  background-image: none;
}
.snippet .about-tv-container {
  margin-top: 60px;
  /** ABOUT **/
  /** DEVICES **/
  /** ADVANTAGES **/
}
.snippet .about-tv-container .about-tv-banner .about-tv-center {
  text-align: center;
}
.snippet .about-tv-container .about-tv-banner .about-tv {
  font-size: 22px;
  margin-top: 75px;
}
.snippet .about-tv-container .about-tv-banner .image-devices {
  max-width: 100%;
}
.snippet .about-tv-container .devices-banner {
  text-align: center;
  margin-bottom: 35px;
}
.snippet .about-tv-container .devices-banner .devices-images img {
  max-width: 8%;
}
.snippet .about-tv-container .devices-banner .devices-images img.wider {
  max-width: 150px;
  margin-right: 20px;
}
.snippet .about-tv-container .advantage-banner {
  margin-bottom: 50px;
}
.snippet .about-tv-container .advantage-banner .brand-background {
  background-color: #0183cc;
}
.snippet .about-tv-container .advantage-banner .brand-background .advantage-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 60px;
}
.snippet .about-tv-container .advantage-banner .brand-background .advantage-row .advantage-column.sm {
  padding: 20px 0px 20px 20px;
}
.snippet .about-tv-container .advantage-banner .brand-background .advantage-row .advantage-column.xl,
.snippet .about-tv-container .advantage-banner .brand-background .advantage-row .advantage-column.md,
.snippet .about-tv-container .advantage-banner .brand-background .advantage-row .advantage-column.xs {
  padding: 20px 20px;
}
.snippet .about-tv-container .advantage-banner .brand-background .advantage-row .advantage-column.lg {
  padding: 20px 10px;
}
.snippet .about-tv-container .advantage-banner .brand-background .advantage-row .advantage-column.lg .text.text-smaller {
  width: 80%;
}
.snippet .about-tv-container .advantage-banner .brand-background .advantage-row .advantage-column.xxs {
  padding: 20px 0;
}
.snippet .about-tv-container .advantage-banner .brand-background .advantage-row .advantage-column.xxs .text.text-smaller {
  font-size: 14px;
}
.snippet .about-tv-container .advantage-banner .brand-background .advantage-row .advantage-column.xxs .text.text-bold {
  font-size: 18px;
  line-height: 1.5;
}
.snippet .about-tv-container .advantage-banner .brand-background .advantage-row .advantage-column.xxs .advantage-image {
  text-align: center;
}
.snippet .about-tv-container .advantage-banner .brand-background .text {
  color: white;
}
.snippet .about-tv-container .advantage-banner .brand-background .text.text-bold {
  font-weight: 500;
  padding-bottom: 0;
  margin-bottom: 10px;
  font-size: 21px;
  line-height: 1;
}
.snippet .about-tv-container .advantage-banner .brand-background .text.text-smaller {
  font-weight: 300;
  font-size: 15px;
  width: 80%;
}
.snippet .about-tv-container .advantage-banner .brand-background .advantage-more {
  text-align: center;
}
.snippet .about-tv-container .advantage-banner .brand-background .advantage-more .advantages-more-button {
  font-size: 18px;
  text-decoration: none;
}
.snippet .about-tv-container .advantage-banner .brand-background .advantage-image.easy-image {
  padding-left: 0;
}
.snippet .about-tv-container .advantage-banner .brand-background.xl,
.snippet .about-tv-container .advantage-banner .brand-background.md {
  padding: 60px 50px;
}
.snippet .about-tv-container .advantage-banner .brand-background.lg:not(.xl),
.snippet .about-tv-container .advantage-banner .brand-background.sm,
.snippet .about-tv-container .advantage-banner .brand-background.xs {
  padding: 60px 30px;
}
.snippet .about-tv-container .advantage-banner .brand-background.xs .advantage-row {
  text-align: center;
}
.snippet .about-tv-container .advantage-banner .brand-background.xs .advantage-row .advantage-left {
  text-align: left;
}
.snippet .about-tv-container .advantage-banner .brand-background.xxs {
  padding: 60px 10px;
}
.snippet .about-tv-container.xl .about-tv p {
  font-size: 22px;
}
.snippet .about-tv-container.xl .devices-images {
  justify-content: center;
}
.snippet .about-tv-container.xl .image-devices {
  padding-top: 30px;
}
.snippet .about-tv-container.lg:not(.xl) .about-tv p {
  font-size: 18px;
}
.snippet .about-tv-container.lg:not(.xl) .image-devices {
  padding-top: 50px;
}
.snippet .about-tv-container.lg .big-image {
  float: right;
}
.snippet .about-tv-container.lg .devices-images img {
  max-width: 6%;
}
.snippet .about-tv-container.xs .sledovanitv-logo {
  max-width: 100%;
}
.snippet .about-tv-container.xs .about-tv-banner .about-tv p {
  font-size: 18px;
  text-align: justify;
}
.snippet .about-tv-container.xs .devices-banner .devices-images img {
  max-width: 100px;
}
.snippet .about-tv-container.xs .devices-banner .devices-images img.wider {
  max-width: 150px;
}
.snippet .allowed-services-container {
  margin-bottom: 60px;
}
.snippet .allowed-services-container .allowed-services-title {
  margin-bottom: 60px;
  color: #02507d;
}
.snippet .allowed-services-container .allowed-services-banner {
  background-color: #ecf8ff;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.snippet .allowed-services-container .allowed-services-banner .allowed-service-content {
  padding-bottom: 60px;
}
.snippet .allowed-services-container .allowed-services-banner .allowed-service-content .service-title {
  font-size: 48px;
  padding-top: 40px;
  padding-bottom: 20px;
  color: #414042;
}
.snippet .allowed-services-container .allowed-services-banner .allowed-service-content .service-count {
  margin-top: 20px;
  color: #02507d;
  margin-bottom: 10px;
  font-size: 24px;
}
.snippet .allowed-services-container .allowed-services-banner .allowed-service-content .service-count-other {
  padding-top: 10px;
}
.snippet .allowed-services-container .allowed-services-banner .allowed-service-content .show-info {
  padding-bottom: 10px;
}
.snippet .allowed-services-container .allowed-services-banner .allowed-service-content .show-info a {
  color: #0183cc;
}
.snippet .allowed-services-container .allowed-services-banner .allowed-service-content .other-services .service-count-other {
  font-weight: 400;
  line-height: 1.3;
}
.snippet .allowed-services-container .allowed-services-banner .allowed-service-content .show-info:hover {
  text-decoration: none;
  cursor: pointer;
}
.snippet .allowed-services-container .allowed-services-banner .allowed-service-info .allowed-service-description {
  margin-bottom: 20px;
}
.snippet .allowed-services-container .allowed-services-banner .allowed-service-info .allowed-service-description p {
  font-weight: 300;
  font-size: 14px;
}
.snippet .allowed-services-container .allowed-services-banner .allowed-service-info .service-more-info .more-info {
  text-decoration: none;
}
.snippet .allowed-services-container.xl .allowed-services-banner .service-info {
  text-align: right;
}
.snippet .allowed-services-container.lg .allowed-services-banner .allowed-service-info .service-price {
  text-align: right;
}
.snippet .allowed-services-container.lg:not(.xl) .allowed-services-banner .service-count {
  font-size: 22px;
}
.snippet .allowed-services-container.lg:not(.xl) .allowed-services-banner .service-info {
  text-align: center;
}
.snippet .allowed-services-container.lg:not(.xl) .allowed-services-banner .service-info .service-price-value {
  font-size: 22px;
}
.snippet .allowed-services-container.md .allowed-services-banner .service-info {
  text-align: right;
}
.snippet .allowed-services-container.xs:not(.xxs) .allowed-services-banner .service-info {
  text-align: right;
}
.snippet .allowed-services-container.xxs .allowed-services-banner .allowed-service-content .images-hidden {
  padding-right: 0;
}
.snippet .allowed-services-container.xxs .allowed-services-banner .service-info {
  text-align: center;
}
.snippet .allowed-services-container.xxs .allowed-services-banner .service-info .more-info {
  width: 100%;
}
.snippet .main-services-container {
  padding-bottom: 40px;
}
.snippet .main-services-container .main-services-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.snippet .main-services-container .main-services-banner .main-service-items {
  padding-bottom: 60px;
}
.snippet .main-services-container .main-services-banner .main-service-items:first-child .main-service-item.lg {
  padding-left: 0;
  padding-right: 30px;
}
.snippet .main-services-container .main-services-banner .main-service-items:last-child .main-service-item.lg {
  padding-left: 30px;
  padding-right: 0;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item {
  margin-bottom: 20px;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item .main-service-title {
  height: 100px;
  font-size: 27px;
  color: #414042;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item .promo-price-col {
  padding: 0 20px;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item .channels-count {
  padding-bottom: 10px;
  font-size: 22px;
  color: #02507d;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item .main-services-show {
  padding-bottom: 10px;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item .main-services-show .show-info {
  padding-top: 20px;
  padding-bottom: 10px;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item .main-services-show .show-info a {
  color: #0183cc;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item .main-services-show .show-info a:hover {
  cursor: pointer;
  text-decoration: none;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item .description {
  margin-top: 20px;
  height: 120px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item .description h5 {
  font-weight: 300;
  color: #414042;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item .main-service-info .main-service-more-info .more-info {
  text-decoration: none;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.lg:not(.xl) .main-service-info .main-service-more-info .more-info {
  width: 100%;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.lg {
  padding-left: 15px;
  padding-right: 15px;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.lg .main-service-info {
  text-align: center;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.lg .main-service-info .main-service-price {
  padding-bottom: 20px;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.md {
  padding-right: 30px;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.md .main-service-more-info .more-info,
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.sm .main-service-more-info .more-info {
  float: right;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.md .main-service-more-info,
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.sm .main-service-more-info,
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.xs .main-service-more-info {
  text-align: right;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.md,
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.sm,
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.xs,
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.xxs {
  padding-left: 0;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.xs,
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.xxs {
  padding-right: 0;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.xxs .main-service-price {
  padding-bottom: 20px;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.xxs .main-service-more-info {
  text-align: center;
}
.snippet .main-services-container .main-services-banner .main-service-items .main-service-item.xxs .main-service-more-info .more-info {
  width: 100%;
}
.snippet .services-container {
  margin-bottom: 30px;
}
.snippet .services-container .other-services-title {
  color: #02507d;
  margin-bottom: 30px;
}
.snippet .services-container .services,
.snippet .services-container .other-services {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.snippet .services-container .services .service-items,
.snippet .services-container .other-services .service-items {
  padding: 20px 40px 60px 0;
}
.snippet .services-container .services .service-items .service-item .service-title,
.snippet .services-container .other-services .service-items .service-item .service-title {
  font-size: 22px;
  height: 80px;
  color: #414042;
}
.snippet .services-container .services .service-items .service-item .channels-count,
.snippet .services-container .other-services .service-items .service-item .channels-count {
  font-size: 22px;
  color: #02507d;
}
.snippet .services-container .services .service-items .service-item .channels-count-fill,
.snippet .services-container .other-services .service-items .service-item .channels-count-fill {
  height: 19px;
  margin-bottom: 10px;
}
.snippet .services-container .services .service-items .service-item .channels-count.small,
.snippet .services-container .other-services .service-items .service-item .channels-count.small {
  font-size: 18px;
  margin-bottom: 10px;
}
.snippet .services-container .services .service-items .service-item .col-no-right-padding,
.snippet .services-container .other-services .service-items .service-item .col-no-right-padding {
  padding-right: 0;
}
.snippet .services-container .services .service-items .service-item .col-no-right-padding .line,
.snippet .services-container .other-services .service-items .service-item .col-no-right-padding .line {
  margin-top: 0;
  background-color: #80c1e5;
  height: 1px;
  border: 0;
  max-width: 100%;
}
.snippet .services-container .services .service-items .service-item .service-price-col .service-price-value,
.snippet .services-container .other-services .service-items .service-item .service-price-col .service-price-value {
  font-size: 20px;
}
.snippet .services-container .services .service-items .service-item .button-more-info,
.snippet .services-container .other-services .service-items .service-item .button-more-info {
  padding-right: 0;
}
.snippet .services-container .services .service-items .service-item .button-more-info .btn.more-info,
.snippet .services-container .other-services .service-items .service-item .button-more-info .btn.more-info {
  float: right;
  text-decoration: none;
}
.snippet .services-container .services .service-items .service-item .images-hidden,
.snippet .services-container .other-services .service-items .service-item .images-hidden {
  margin-bottom: 20px;
  overflow: hidden;
  height: 100px;
}
.snippet .services-container .services .service-items .service-item .description .multiline-text,
.snippet .services-container .other-services .service-items .service-item .description .multiline-text {
  font-weight: 300;
  color: #414042;
}
.snippet .services-container .services .service-items .service-item .description .toggle-button-div,
.snippet .services-container .other-services .service-items .service-item .description .toggle-button-div {
  padding: 0;
}
.snippet .services-container .services .service-items .service-item .description .toggle-button-div .toggle-button,
.snippet .services-container .other-services .service-items .service-item .description .toggle-button-div .toggle-button {
  float: right;
}
.snippet .services-container .services .service-items .service-item .description .toggle-button-div .toggle-button .plus-button,
.snippet .services-container .other-services .service-items .service-item .description .toggle-button-div .toggle-button .plus-button {
  float: right;
  text-decoration: none;
  font-size: 30px;
  background-color: #ecf8ff;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.snippet .services-container .services .service-items .service-item .description .toggle-button-div .toggle-button .plus-button:hover,
.snippet .services-container .other-services .service-items .service-item .description .toggle-button-div .toggle-button .plus-button:hover {
  background-color: #0183cc;
  color: white;
}
.snippet .services-container .services .service-items .service-item .description .toggle-button-div .toggle-button:hover,
.snippet .services-container .other-services .service-items .service-item .description .toggle-button-div .toggle-button:hover,
.snippet .services-container .services .service-items .service-item .description .toggle-button-div .toggle-button:active,
.snippet .services-container .other-services .service-items .service-item .description .toggle-button-div .toggle-button:active,
.snippet .services-container .services .service-items .service-item .description .toggle-button-div .toggle-button:focus,
.snippet .services-container .other-services .service-items .service-item .description .toggle-button-div .toggle-button:focus {
  text-decoration: none;
  cursor: pointer;
}
.snippet .services-container .services .service-items .service-item.lg:not(.xl) .service-price-col,
.snippet .services-container .other-services .service-items .service-item.lg:not(.xl) .service-price-col {
  padding-right: 0;
  padding-bottom: 20px;
}
.snippet .services-container .services .service-items .service-item.lg:not(.xl) .button-more-info .more-info,
.snippet .services-container .other-services .service-items .service-item.lg:not(.xl) .button-more-info .more-info {
  width: 100%;
}
.snippet .services-container .services .service-items .service-item.xxs .service-title,
.snippet .services-container .other-services .service-items .service-item.xxs .service-title {
  font-size: 20px;
}
.snippet .services-container .services .service-items .service-item.xxs .service-price-col,
.snippet .services-container .other-services .service-items .service-item.xxs .service-price-col {
  padding-right: 0;
  margin-bottom: 20px;
}
.snippet .services-container .services .service-items .service-item.xxs .button-more-info,
.snippet .services-container .other-services .service-items .service-item.xxs .button-more-info {
  text-align: center;
}
.snippet .services-container .services .service-items .service-item.xxs .button-more-info .more-info,
.snippet .services-container .other-services .service-items .service-item.xxs .button-more-info .more-info {
  width: 100%;
}
.snippet .services-container.xxs .services .service-items,
.snippet .services-container.xxs .other-services .service-items {
  padding: 20px 15px 60px 0;
}
.snippet .messages {
  margin-bottom: 60px;
}
.snippet .tv-tips-container {
  padding-bottom: 60px;
  text-align: center;
}
.snippet .tv-tips-container .tv-tips-title {
  margin-bottom: 30px;
  text-align: left;
  color: #02507d;
}
.snippet .tv-tips-container .tv-tip-item {
  text-align: left;
  width: 200px;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;
}
.snippet .tv-tips-container .tv-tip-item:last-child {
  margin-right: 0;
}
.snippet .tv-tips-container .tv-tip-item:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition-duration: 0.2s;
}
.snippet .tv-tips-container .tv-tip-item img {
  max-width: 200px;
  min-height: 300px;
}
.snippet .tv-tips-container .tv-tip-item.no-right-margin {
  margin-right: 0;
}
.snippet .tv-tips-container .tv-tip-item .tips-text {
  background-color: #1a1a19;
  width: 200px;
}
.snippet .tv-tips-container .tv-tip-item .tips-text .tips-name {
  font-size: 22px;
  padding-left: 5px;
  line-height: 34px;
  color: #CCCCCC;
  height: 34px;
  width: 190px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}
.snippet .tv-tips-container .tv-tip-item .tips-available {
  font-size: 18px;
  padding-left: 5px;
  width: 200px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 0;
}
.snippet .tv-tips-container .tv-tip-item .tips-package {
  font-size: 18px;
  padding-left: 5px;
  width: 200px;
  line-height: 30px;
  color: #FFFFFF;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.snippet .tv-tips-container .tv-tip-item .tips-content {
  background-color: #0183CC;
}
.snippet {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #414042;
}
.snippet h1,
.snippet h2,
.snippet h3,
.snippet h4,
.snippet h5,
.snippet h6,
.snippet p {
  padding: 0;
  margin: 0;
}
.snippet h1:before,
.snippet h2:before,
.snippet h3:before,
.snippet h4:before,
.snippet h5:before,
.snippet h6:before,
.snippet p:before {
  display: none;
}
.snippet h1.service-title:before,
.snippet h2.service-title:before,
.snippet h3.service-title:before,
.snippet h4.service-title:before,
.snippet h5.service-title:before,
.snippet h6.service-title:before,
.snippet p.service-title:before {
  display: none;
}
.snippet .snippet-container-fluid .images-hidden {
  position: static;
}
.snippet .images-hidden {
  position: static;
  height: 100px;
  overflow: hidden;
  margin-bottom: 20px;
}
.snippet .images-hidden .tv-logos,
.snippet .images-hidden .radio-logos {
  width: 50px;
}
.snippet .multiline-text {
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  height: 35.4px;
  /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.3;
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
}
.snippet .multiline-text * {
  font-size: 14px;
  line-height: 1.3;
}
.snippet .service-price {
  background-color: #ecf8ff;
  text-align: center;
  height: 50px;
}
.snippet .service-price-value {
  font-size: 26px;
  color: #02507d;
  display: inline-block;
  line-height: 50px;
}
.snippet .service-price-duration {
  font-size: 14px;
  color: #02507d;
  display: inline-block;
  line-height: 50px;
}
.snippet .btn.more-info {
  font-size: 18px;
  border-color: #0183cc;
  width: 155px;
}
.snippet .promo-price-circle {
  float: right;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #df2546;
  background-color: #df2546;
  text-align: center;
  color: white;
}
.snippet .promo-price-circle .promo-price-duration {
  font-size: 22px;
  margin-top: 15px;
  line-height: 1.2;
}
.snippet .promo-price-circle .promo-price-free {
  font-size: 14px;
}
.snippet .image-container {
  height: 50px;
  /* equals max image height */
  width: auto;
  float: left;
  white-space: nowrap;
  display: inline;
}
.snippet .image-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.snippet .tooltip-container {
  visibility: hidden;
  position: absolute;
}
.snippet .image-container:hover .tooltip-container {
  visibility: visible;
}
.snippet .logos-tooltip {
  display: block;
  position: absolute;
  top: -15px;
  left: 30px;
  width: 300px;
  background: #444;
  color: #f9f9f9;
  padding: 10px 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  cursor: default;
  z-index: 9999;
}
.snippet .logos-tooltip .logos-tooltip-head,
.snippet .logos-tooltip .logos-tooltip-options,
.snippet .logos-tooltip .logos-tooltip-description {
  display: block;
  padding: 3px 0;
  width: 100%;
}
.snippet .logos-tooltip .logos-tooltip-head {
  font-weight: bold;
  font-size: 16px;
}
.snippet .logos-tooltip .logos-tooltip-options {
  overflow: hidden;
}
.snippet .logos-tooltip .logos-tooltip-language {
  font-style: italic;
  float: left;
  font-size: 14px;
}
.snippet .logos-tooltip .logos-tooltip-services {
  float: right;
}
.snippet .logos-tooltip .logos-tooltip-description {
  white-space: normal;
  font-size: 14px;
}
.snippet .logos-tooltip-img {
  width: 30px;
  height: auto;
  cursor: help;
  margin-left: 5px;
  background: #444;
}
.snippet .snippet-container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.snippet .snippet-container-fluid .snippet-row {
  margin-left: -15px;
  margin-right: -15px;
}
.snippet .snippet-container-fluid .snippet-col-xs-1,
.snippet .snippet-container-fluid .snippet-col-sm-1,
.snippet .snippet-container-fluid .snippet-col-md-1,
.snippet .snippet-container-fluid .snippet-col-lg-1,
.snippet .snippet-container-fluid .snippet-col-xs-2,
.snippet .snippet-container-fluid .snippet-col-sm-2,
.snippet .snippet-container-fluid .snippet-col-md-2,
.snippet .snippet-container-fluid .snippet-col-lg-2,
.snippet .snippet-container-fluid .snippet-col-xs-3,
.snippet .snippet-container-fluid .snippet-col-sm-3,
.snippet .snippet-container-fluid .snippet-col-md-3,
.snippet .snippet-container-fluid .snippet-col-lg-3,
.snippet .snippet-container-fluid .snippet-col-xs-4,
.snippet .snippet-container-fluid .snippet-col-sm-4,
.snippet .snippet-container-fluid .snippet-col-md-4,
.snippet .snippet-container-fluid .snippet-col-lg-4,
.snippet .snippet-container-fluid .snippet-col-xs-5,
.snippet .snippet-container-fluid .snippet-col-sm-5,
.snippet .snippet-container-fluid .snippet-col-md-5,
.snippet .snippet-container-fluid .snippet-col-lg-5,
.snippet .snippet-container-fluid .snippet-col-xs-6,
.snippet .snippet-container-fluid .snippet-col-sm-6,
.snippet .snippet-container-fluid .snippet-col-md-6,
.snippet .snippet-container-fluid .snippet-col-lg-6,
.snippet .snippet-container-fluid .snippet-col-xs-7,
.snippet .snippet-container-fluid .snippet-col-sm-7,
.snippet .snippet-container-fluid .snippet-col-md-7,
.snippet .snippet-container-fluid .snippet-col-lg-7,
.snippet .snippet-container-fluid .snippet-col-xs-8,
.snippet .snippet-container-fluid .snippet-col-sm-8,
.snippet .snippet-container-fluid .snippet-col-md-8,
.snippet .snippet-container-fluid .snippet-col-lg-8,
.snippet .snippet-container-fluid .snippet-col-xs-9,
.snippet .snippet-container-fluid .snippet-col-sm-9,
.snippet .snippet-container-fluid .snippet-col-md-9,
.snippet .snippet-container-fluid .snippet-col-lg-9,
.snippet .snippet-container-fluid .snippet-col-xs-10,
.snippet .snippet-container-fluid .snippet-col-sm-10,
.snippet .snippet-container-fluid .snippet-col-md-10,
.snippet .snippet-container-fluid .snippet-col-lg-10,
.snippet .snippet-container-fluid .snippet-col-xs-11,
.snippet .snippet-container-fluid .snippet-col-sm-11,
.snippet .snippet-container-fluid .snippet-col-md-11,
.snippet .snippet-container-fluid .snippet-col-lg-11,
.snippet .snippet-container-fluid .snippet-col-xs-12,
.snippet .snippet-container-fluid .snippet-col-sm-12,
.snippet .snippet-container-fluid .snippet-col-md-12,
.snippet .snippet-container-fluid .snippet-col-lg-12 {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-1,
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-2,
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-3,
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-4,
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-5,
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-6,
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-7,
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-8,
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-9,
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-10,
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-11,
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-12 {
  float: left;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-12 {
  width: 100%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-11 {
  width: 91.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-10 {
  width: 83.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-9 {
  width: 75%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-8 {
  width: 66.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-7 {
  width: 58.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-6 {
  width: 50%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-5 {
  width: 41.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-4 {
  width: 33.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-3 {
  width: 25%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-2 {
  width: 16.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-1 {
  width: 8.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-pull-12 {
  right: 100%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-pull-11 {
  right: 91.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-pull-10 {
  right: 83.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-pull-9 {
  right: 75%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-pull-8 {
  right: 66.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-pull-7 {
  right: 58.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-pull-6 {
  right: 50%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-pull-5 {
  right: 41.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-pull-4 {
  right: 33.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-pull-3 {
  right: 25%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-pull-2 {
  right: 16.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-pull-1 {
  right: 8.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-pull-0 {
  right: auto;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-push-12 {
  left: 100%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-push-11 {
  left: 91.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-push-10 {
  left: 83.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-push-9 {
  left: 75%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-push-8 {
  left: 66.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-push-7 {
  left: 58.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-push-6 {
  left: 50%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-push-5 {
  left: 41.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-push-4 {
  left: 33.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-push-3 {
  left: 25%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-push-2 {
  left: 16.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-push-1 {
  left: 8.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-push-0 {
  left: auto;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-offset-12 {
  margin-left: 100%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-offset-11 {
  margin-left: 91.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-offset-10 {
  margin-left: 83.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-offset-9 {
  margin-left: 75%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-offset-8 {
  margin-left: 66.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-offset-7 {
  margin-left: 58.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-offset-6 {
  margin-left: 50%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-offset-5 {
  margin-left: 41.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-offset-4 {
  margin-left: 33.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-offset-3 {
  margin-left: 25%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-offset-2 {
  margin-left: 16.66666667%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-offset-1 {
  margin-left: 8.33333333%;
}
.snippet .snippet-container-fluid.xxs .snippet-col-xxs-offset-0 {
  margin-left: 0%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-1,
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-2,
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-3,
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-4,
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-5,
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-6,
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-7,
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-8,
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-9,
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-10,
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-11,
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-12 {
  float: left;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-12 {
  width: 100%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-11 {
  width: 91.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-10 {
  width: 83.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-9 {
  width: 75%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-8 {
  width: 66.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-7 {
  width: 58.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-6 {
  width: 50%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-5 {
  width: 41.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-4 {
  width: 33.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-3 {
  width: 25%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-2 {
  width: 16.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-1 {
  width: 8.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-pull-12 {
  right: 100%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-pull-11 {
  right: 91.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-pull-10 {
  right: 83.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-pull-9 {
  right: 75%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-pull-8 {
  right: 66.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-pull-7 {
  right: 58.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-pull-6 {
  right: 50%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-pull-5 {
  right: 41.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-pull-4 {
  right: 33.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-pull-3 {
  right: 25%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-pull-2 {
  right: 16.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-pull-1 {
  right: 8.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-pull-0 {
  right: auto;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-push-12 {
  left: 100%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-push-11 {
  left: 91.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-push-10 {
  left: 83.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-push-9 {
  left: 75%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-push-8 {
  left: 66.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-push-7 {
  left: 58.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-push-6 {
  left: 50%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-push-5 {
  left: 41.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-push-4 {
  left: 33.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-push-3 {
  left: 25%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-push-2 {
  left: 16.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-push-1 {
  left: 8.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-push-0 {
  left: auto;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-offset-12 {
  margin-left: 100%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-offset-9 {
  margin-left: 75%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-offset-6 {
  margin-left: 50%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-offset-3 {
  margin-left: 25%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.snippet .snippet-container-fluid.xs:not(.xxs) .snippet-col-xs-offset-0 {
  margin-left: 0%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-1,
.snippet .snippet-container-fluid.sm .snippet-col-sm-2,
.snippet .snippet-container-fluid.sm .snippet-col-sm-3,
.snippet .snippet-container-fluid.sm .snippet-col-sm-4,
.snippet .snippet-container-fluid.sm .snippet-col-sm-5,
.snippet .snippet-container-fluid.sm .snippet-col-sm-6,
.snippet .snippet-container-fluid.sm .snippet-col-sm-7,
.snippet .snippet-container-fluid.sm .snippet-col-sm-8,
.snippet .snippet-container-fluid.sm .snippet-col-sm-9,
.snippet .snippet-container-fluid.sm .snippet-col-sm-10,
.snippet .snippet-container-fluid.sm .snippet-col-sm-11,
.snippet .snippet-container-fluid.sm .snippet-col-sm-12 {
  float: left;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-12 {
  width: 100%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-11 {
  width: 91.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-10 {
  width: 83.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-9 {
  width: 75%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-8 {
  width: 66.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-7 {
  width: 58.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-6 {
  width: 50%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-5 {
  width: 41.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-4 {
  width: 33.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-3 {
  width: 25%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-2 {
  width: 16.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-1 {
  width: 8.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-pull-12 {
  right: 100%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-pull-11 {
  right: 91.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-pull-10 {
  right: 83.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-pull-9 {
  right: 75%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-pull-8 {
  right: 66.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-pull-7 {
  right: 58.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-pull-6 {
  right: 50%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-pull-5 {
  right: 41.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-pull-4 {
  right: 33.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-pull-3 {
  right: 25%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-pull-2 {
  right: 16.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-pull-1 {
  right: 8.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-pull-0 {
  right: auto;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-push-12 {
  left: 100%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-push-11 {
  left: 91.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-push-10 {
  left: 83.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-push-9 {
  left: 75%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-push-8 {
  left: 66.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-push-7 {
  left: 58.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-push-6 {
  left: 50%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-push-5 {
  left: 41.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-push-4 {
  left: 33.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-push-3 {
  left: 25%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-push-2 {
  left: 16.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-push-1 {
  left: 8.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-push-0 {
  left: auto;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-offset-12 {
  margin-left: 100%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-offset-11 {
  margin-left: 91.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-offset-10 {
  margin-left: 83.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-offset-9 {
  margin-left: 75%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-offset-8 {
  margin-left: 66.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-offset-7 {
  margin-left: 58.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-offset-6 {
  margin-left: 50%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-offset-5 {
  margin-left: 41.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-offset-4 {
  margin-left: 33.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-offset-3 {
  margin-left: 25%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-offset-2 {
  margin-left: 16.66666667%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-offset-1 {
  margin-left: 8.33333333%;
}
.snippet .snippet-container-fluid.sm .snippet-col-sm-offset-0 {
  margin-left: 0%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-1,
.snippet .snippet-container-fluid.md .snippet-col-md-2,
.snippet .snippet-container-fluid.md .snippet-col-md-3,
.snippet .snippet-container-fluid.md .snippet-col-md-4,
.snippet .snippet-container-fluid.md .snippet-col-md-5,
.snippet .snippet-container-fluid.md .snippet-col-md-6,
.snippet .snippet-container-fluid.md .snippet-col-md-7,
.snippet .snippet-container-fluid.md .snippet-col-md-8,
.snippet .snippet-container-fluid.md .snippet-col-md-9,
.snippet .snippet-container-fluid.md .snippet-col-md-10,
.snippet .snippet-container-fluid.md .snippet-col-md-11,
.snippet .snippet-container-fluid.md .snippet-col-md-12 {
  float: left;
}
.snippet .snippet-container-fluid.md .snippet-col-md-12 {
  width: 100%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-11 {
  width: 91.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-10 {
  width: 83.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-9 {
  width: 75%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-8 {
  width: 66.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-7 {
  width: 58.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-6 {
  width: 50%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-5 {
  width: 41.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-4 {
  width: 33.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-3 {
  width: 25%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-2 {
  width: 16.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-1 {
  width: 8.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-pull-12 {
  right: 100%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-pull-11 {
  right: 91.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-pull-10 {
  right: 83.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-pull-9 {
  right: 75%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-pull-8 {
  right: 66.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-pull-7 {
  right: 58.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-pull-6 {
  right: 50%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-pull-5 {
  right: 41.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-pull-4 {
  right: 33.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-pull-3 {
  right: 25%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-pull-2 {
  right: 16.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-pull-1 {
  right: 8.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-pull-0 {
  right: auto;
}
.snippet .snippet-container-fluid.md .snippet-col-md-push-12 {
  left: 100%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-push-11 {
  left: 91.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-push-10 {
  left: 83.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-push-9 {
  left: 75%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-push-8 {
  left: 66.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-push-7 {
  left: 58.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-push-6 {
  left: 50%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-push-5 {
  left: 41.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-push-4 {
  left: 33.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-push-3 {
  left: 25%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-push-2 {
  left: 16.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-push-1 {
  left: 8.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-push-0 {
  left: auto;
}
.snippet .snippet-container-fluid.md .snippet-col-md-offset-12 {
  margin-left: 100%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-offset-11 {
  margin-left: 91.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-offset-10 {
  margin-left: 83.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-offset-9 {
  margin-left: 75%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-offset-8 {
  margin-left: 66.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-offset-7 {
  margin-left: 58.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-offset-6 {
  margin-left: 50%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-offset-5 {
  margin-left: 41.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-offset-4 {
  margin-left: 33.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-offset-3 {
  margin-left: 25%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-offset-2 {
  margin-left: 16.66666667%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-offset-1 {
  margin-left: 8.33333333%;
}
.snippet .snippet-container-fluid.md .snippet-col-md-offset-0 {
  margin-left: 0%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-1,
.snippet .snippet-container-fluid.lg .snippet-col-lg-2,
.snippet .snippet-container-fluid.lg .snippet-col-lg-3,
.snippet .snippet-container-fluid.lg .snippet-col-lg-4,
.snippet .snippet-container-fluid.lg .snippet-col-lg-5,
.snippet .snippet-container-fluid.lg .snippet-col-lg-6,
.snippet .snippet-container-fluid.lg .snippet-col-lg-7,
.snippet .snippet-container-fluid.lg .snippet-col-lg-8,
.snippet .snippet-container-fluid.lg .snippet-col-lg-9,
.snippet .snippet-container-fluid.lg .snippet-col-lg-10,
.snippet .snippet-container-fluid.lg .snippet-col-lg-11,
.snippet .snippet-container-fluid.lg .snippet-col-lg-12 {
  float: left;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-12 {
  width: 100%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-11 {
  width: 91.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-10 {
  width: 83.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-9 {
  width: 75%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-8 {
  width: 66.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-7 {
  width: 58.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-6 {
  width: 50%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-5 {
  width: 41.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-4 {
  width: 33.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-3 {
  width: 25%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-2 {
  width: 16.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-1 {
  width: 8.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-pull-12 {
  right: 100%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-pull-11 {
  right: 91.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-pull-10 {
  right: 83.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-pull-9 {
  right: 75%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-pull-8 {
  right: 66.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-pull-7 {
  right: 58.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-pull-6 {
  right: 50%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-pull-5 {
  right: 41.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-pull-4 {
  right: 33.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-pull-3 {
  right: 25%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-pull-2 {
  right: 16.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-pull-1 {
  right: 8.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-pull-0 {
  right: auto;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-push-12 {
  left: 100%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-push-11 {
  left: 91.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-push-10 {
  left: 83.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-push-9 {
  left: 75%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-push-8 {
  left: 66.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-push-7 {
  left: 58.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-push-6 {
  left: 50%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-push-5 {
  left: 41.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-push-4 {
  left: 33.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-push-3 {
  left: 25%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-push-2 {
  left: 16.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-push-1 {
  left: 8.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-push-0 {
  left: auto;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-offset-12 {
  margin-left: 100%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-offset-11 {
  margin-left: 91.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-offset-10 {
  margin-left: 83.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-offset-9 {
  margin-left: 75%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-offset-8 {
  margin-left: 66.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-offset-7 {
  margin-left: 58.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-offset-6 {
  margin-left: 50%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-offset-5 {
  margin-left: 41.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-offset-4 {
  margin-left: 33.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-offset-3 {
  margin-left: 25%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-offset-2 {
  margin-left: 16.66666667%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-offset-1 {
  margin-left: 8.33333333%;
}
.snippet .snippet-container-fluid.lg .snippet-col-lg-offset-0 {
  margin-left: 0%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-1,
.snippet .snippet-container-fluid.xl .snippet-col-xl-2,
.snippet .snippet-container-fluid.xl .snippet-col-xl-3,
.snippet .snippet-container-fluid.xl .snippet-col-xl-4,
.snippet .snippet-container-fluid.xl .snippet-col-xl-5,
.snippet .snippet-container-fluid.xl .snippet-col-xl-6,
.snippet .snippet-container-fluid.xl .snippet-col-xl-7,
.snippet .snippet-container-fluid.xl .snippet-col-xl-8,
.snippet .snippet-container-fluid.xl .snippet-col-xl-9,
.snippet .snippet-container-fluid.xl .snippet-col-xl-10,
.snippet .snippet-container-fluid.xl .snippet-col-xl-11,
.snippet .snippet-container-fluid.xl .snippet-col-xl-12 {
  float: left;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-12 {
  width: 100%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-11 {
  width: 91.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-10 {
  width: 83.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-9 {
  width: 75%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-8 {
  width: 66.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-7 {
  width: 58.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-6 {
  width: 50%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-5 {
  width: 41.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-4 {
  width: 33.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-3 {
  width: 25%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-2 {
  width: 16.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-1 {
  width: 8.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-pull-12 {
  right: 100%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-pull-11 {
  right: 91.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-pull-10 {
  right: 83.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-pull-9 {
  right: 75%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-pull-8 {
  right: 66.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-pull-7 {
  right: 58.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-pull-6 {
  right: 50%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-pull-5 {
  right: 41.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-pull-4 {
  right: 33.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-pull-3 {
  right: 25%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-pull-2 {
  right: 16.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-pull-1 {
  right: 8.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-pull-0 {
  right: auto;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-push-12 {
  left: 100%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-push-11 {
  left: 91.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-push-10 {
  left: 83.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-push-9 {
  left: 75%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-push-8 {
  left: 66.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-push-7 {
  left: 58.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-push-6 {
  left: 50%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-push-5 {
  left: 41.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-push-4 {
  left: 33.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-push-3 {
  left: 25%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-push-2 {
  left: 16.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-push-1 {
  left: 8.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-push-0 {
  left: auto;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-offset-12 {
  margin-left: 100%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-offset-11 {
  margin-left: 91.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-offset-10 {
  margin-left: 83.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-offset-9 {
  margin-left: 75%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-offset-8 {
  margin-left: 66.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-offset-7 {
  margin-left: 58.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-offset-6 {
  margin-left: 50%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-offset-5 {
  margin-left: 41.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-offset-4 {
  margin-left: 33.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-offset-3 {
  margin-left: 25%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-offset-2 {
  margin-left: 16.66666667%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-offset-1 {
  margin-left: 8.33333333%;
}
.snippet .snippet-container-fluid.xl .snippet-col-xl-offset-0 {
  margin-left: 0%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-1,
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-2,
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-3,
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-4,
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-5,
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-6,
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-7,
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-8,
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-9,
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-10,
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-11,
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-12 {
  float: left;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-12 {
  width: 100%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-11 {
  width: 91.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-10 {
  width: 83.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-9 {
  width: 75%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-8 {
  width: 66.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-7 {
  width: 58.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-6 {
  width: 50%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-5 {
  width: 41.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-4 {
  width: 33.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-3 {
  width: 25%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-2 {
  width: 16.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-1 {
  width: 8.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-pull-12 {
  right: 100%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-pull-11 {
  right: 91.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-pull-10 {
  right: 83.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-pull-9 {
  right: 75%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-pull-8 {
  right: 66.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-pull-7 {
  right: 58.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-pull-6 {
  right: 50%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-pull-5 {
  right: 41.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-pull-4 {
  right: 33.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-pull-3 {
  right: 25%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-pull-2 {
  right: 16.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-pull-1 {
  right: 8.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-pull-0 {
  right: auto;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-push-12 {
  left: 100%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-push-11 {
  left: 91.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-push-10 {
  left: 83.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-push-9 {
  left: 75%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-push-8 {
  left: 66.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-push-7 {
  left: 58.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-push-6 {
  left: 50%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-push-5 {
  left: 41.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-push-4 {
  left: 33.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-push-3 {
  left: 25%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-push-2 {
  left: 16.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-push-1 {
  left: 8.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-push-0 {
  left: auto;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-offset-12 {
  margin-left: 100%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-offset-11 {
  margin-left: 91.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-offset-10 {
  margin-left: 83.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-offset-9 {
  margin-left: 75%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-offset-8 {
  margin-left: 66.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-offset-7 {
  margin-left: 58.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-offset-6 {
  margin-left: 50%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-offset-5 {
  margin-left: 41.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-offset-4 {
  margin-left: 33.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-offset-3 {
  margin-left: 25%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-offset-2 {
  margin-left: 16.66666667%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-offset-1 {
  margin-left: 8.33333333%;
}
.snippet .snippet-container-fluid.xxl .snippet-col-xxl-offset-0 {
  margin-left: 0%;
}
